<script setup lang="ts">
import { TabInfo } from '../type'
import { IonSegment, IonSegmentButton } from '@ionic/vue';

const emit = defineEmits<{
  (e: 'tabChange', tabId: number): void
  (e: 'update:tabId', tabId: number): void
}>()
const tabId = defineModel<number>('tabId', { required: true })
defineProps<{
  tabList: TabInfo[],
  tabPositionClassName: string
}
>()
const ionChange = (e: CustomEvent) => {
  const typeId = e.detail.value
  emit('tabChange', typeId)
  emit('update:tabId', typeId)
}

</script>
<template>
  <div :class="['tab-list', tabPositionClassName]">
    <ion-segment :value="tabId" :scrollable="true" mode='md' @ionChange="ionChange">

      <ion-segment-button v-for="(it) in tabList" :value="it.id" mode='md'>
        <div :class="['textColor', { active: it.id === tabId }]">
          {{ it.title }}
        </div>

      </ion-segment-button>



    </ion-segment>

  </div>
</template>

<style scoped lang="less">
//公用样式
.tab-list {
  width: 21.875rem;
  height: 3.125rem;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;

  .textColor {
    font-size: 12px;
  }


}

#withdraw-main-bindBankTab-index {
  .style() {
    .tab-list {
      border: 1px solid var(--color-line);
      background-color: var(--color-bg-200);


      ion-segment-button {
        height: 3.125rem;
        min-width: 7.25rem;
        text-align: center;
        --background: var(--color-bg-200);
        --background-checked: var(--color-bg-100);
        --border-width: 2px;
        --color: var(--color-text-40);
        --color-checked: var(--color-text-100);
        --indicator-color: var(--theme-color-800);
        --background-hover: var(--color-bg-100);
        --background-hover-opacity: 0.5;
        --padding-start: 0;
        --padding-end: 0
      }
    }

    ion-segment-button.segment-button-checked {
      font-weight: 700;

    }

    .tab-list.top {
      border-radius: var(--rounded-middle) var(--rounded-middle) 0 0;
      border-bottom-width: 0 !important;
    }

    .tab-list.bottom {
      border-radius: 0 0 var(--rounded-middle) var(--rounded-middle);
      border-top-width: 0 !important;
      ion-segment-button{
        --indicator-color: var(--color-bg-200);
      }

      .textColor {
        width: 100%;
        height: 100%;
        line-height: 3.125rem;

      }

      .active {
        border-top: 1px solid var(--theme-color-800);
      }
    }

  }



}

html {
  #withdraw-main-bindBankTab-index.style()
}
</style>
